import * as web3 from '@solana/web3.js';

export const FIRST_CREATOR_IS_LAST_INDEX = false ;//false; // change to false for mainnet as it's the first index 
                                                // instead of last


export const NETWORK = "mainnet-beta";

export const programId : web3.PublicKey = 

new web3.PublicKey("8mLpg346xfiB75bqkvmdf4GHgdGXG3JZ9F2E6cY9bz4a");

// for $DAWG token 
export const DC_TOKEN_DECIMAL = 9; 

export const DC_TOKEN_MINT = "9XxqhRpiNQJ4dViBY1A6nSaUoUgQ9m9JZjCwj3oVu3We";

//"9QKHcZihXMdBgYdrWgqXCP6o6GWWb9ttsqS7WXSVuJZ8";

// This is the wallet address that created the $DAWG token vault
export const DC_TOKEN_VAULT_OWNER = "2fjgDtwG4pLgoEnkrfHJ6bywKWyVbauEkFs6cQBPfvmC";


// NFT token vault API URI
export const NFT_TOKEN_VAULT_API = "https://staking.hiddenboyz.io/api/tokenvault?mint=";

export const NFT_TOKEN_VAULT_FILE_WALLET = "7aj4UGaySYg166nbiFRym7PoSYK75gmRz2vT97bxBr7V";

export const STAKE_DATA_API = "https://staking.hiddenboyz.io/api/stakeData";


export const SIGN_IN_API = "https://staking.hiddenboyz.io/api/signin";


export const SIGN_OUT_API = "https://staking.hiddenboyz.io/api/signout";

// for DC NFT 
export const DC_UPDATE_AUTHORITY = "FNASMx84nrWq8D4iKea4AXtNQf5K74vet4m6Pu1Doz2C";

export const DC_VALID_FIRST_CREATOR = "";
